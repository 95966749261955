.listTestButton {
    &:hover {
        background-color: #ebecec !important;
    }
}

.listTestButton:hover p {
    color: #383e44;
}

.listTestButton:hover svg {
    color: #383e44;
}

.listTestButton {
    z-index: 1;
    &.Mui-selected {
        background-color: #067bff !important;
    }

    &.Mui-selected p {
        color: white !important;
    }

    &.Mui-selected svg {
        color: white !important;
    }

    &.Mui-selected {
        &:hover p {
            color: white;
        }

        &:hover svg {
            color: white;
        }
    }
}
